/**
 * Footer Slide
 * 
 * Author: Wouter Dolk
 */
 var footer_slide = function(){
    var self = this;

    self.init = function(){
        //set var animated
        var animated = false;
        //scroll function
        $(window).scroll(function() {
            //if the screen window is at the bottom and var animated is false
            if($(window).scrollTop() + $(window).height() === $(document).height() && !animated ) {
                //set animated to true
                animated = true;
                // give the footer a slide in effect by changing the margin to 0
                $('.js-download-wrapper').css({'margin-top': 0});
                // scroll down while the footer is sliding in
                $('html, body').animate( { scrollTop: $(document).height()}, 400);
            }
            
            self.checkOffset();
        });
    }

    /**
     * Keep the download buttons bar fixed untill footer is in view
     * Credits: https://stackoverflow.com/questions/8653025/stop-fixed-position-at-footer
     * 
     * @author Robin van Leeuwen <robin@click.nl>
     * @return void
     */
    self.checkOffset = function() {
        // Make position static if it has been downed under 10px before the footer.
        if( $('.js-download-wrapper').offset().top + $('.js-download-wrapper').height() >= $('.footer-wrapper').offset().top - 10) {
            $('.js-download-wrapper').css('position', 'static');
        }
    
        // Restore to position fixed when scroll up
        if( $(document).scrollTop() + window.innerHeight < $('.footer-wrapper').offset().top) {
            $('.js-download-wrapper').css('position', 'fixed');
        }
    }
}

