/**
 * Mobile menu
 * 
 * Author: Wouter Dolk
 */
 var mobile_menu = function(){
    var self = this;

    // set the variables
    var sticky = $('.js-sticky');

    self.animating = false;
    self.init = function(){

        // click function on the mobile icon
        $('.js-menu-icon').click( function(e){
            if( !self.animating ){
              self.animating = true;
              e.preventDefault();
                // slide down the menu after the clicking the menu icon
                // if the mobile menu is open remove it 
                if( sticky.hasClass('expanded')){
                    sticky.removeClass('expanded');
                    $('body').removeClass('no-scroll');
                // otherwise add it
                }else{
                    sticky.addClass('expanded');
                    $('body').addClass('no-scroll');
                }
                // the transition for the menu icon after being clicked
                if( $('.js-icon').hasClass('crossed')){
                    $('.js-icon').removeClass('crossed');
                    $('.js-icon').addClass('collapsed');
                    setTimeout(close, 200);
                }else{
                    $('.js-icon').addClass('collapsed');
                    setTimeout(open, 200);
                }
            }
        });

        // functions for the menu icon transition
        function open (){
            $('.js-icon').removeClass('collapsed');
            $('.js-icon').addClass('crossed');
            self.animating = false;
        }
        function close (){
            $('.js-icon').removeClass('collapsed');
            self.animating = false;
        }

        // click function when an anchor item is clicked
        $('.js-menu-anchor').click(function(){
            // remove the expanded class
            if( sticky.hasClass('expanded')){
                sticky.removeClass('expanded');
                $('body').removeClass('no-scroll');
            }
            // close the menu icon from cross to normal
            if( $('.js-icon').hasClass('crossed')){
                $('.js-icon').removeClass('crossed');
                $('.js-icon').addClass('collapsed');
                setTimeout(close, 200);
            }else{
                $('.js-icon').addClass('collapsed');
                setTimeout(open, 200);
            }
        });

        // if the window size is bigger than 768px remove the classes for the expanded mobile menu
        $(window).on('resize orientationchange',function() {
            if (window.innerWidth > 768) {
                if( sticky.hasClass('expanded')){
                    sticky.removeClass('expanded');
                    $('body').removeClass('no-scroll');
                }
                if( $('.js-icon').hasClass('crossed')){
                    $('.js-icon').removeClass('crossed');
                }
            }
        });
    }
}