/**
 * Slick slider
 * 
 * Author: Wouter Dolk
 */
var slick_slider = function(){
    var self = this;
    var $slider = $('.js-slick-wrapper');
    
    self.init = function(){
        // the settings for the slick slider
        $slider.slick({
            dots: true,
            infinite: true,
            speed: 700,
            fade: true,
            cssEase: 'ease',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 7000,
            // prevArrow: '<button type="button" class="slick-prev-button"><span class="icon-arrow-65"></span></button>',
            // nextArrow: '<button type="button" class="slick-next-button"><span class="icon-arrow-66"></span></button>',
            appendArrows: $('.js-slick-arrows'),
        });
    }
}