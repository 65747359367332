/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 * 
 */

/* ANIMATE HEADER AND TOPBAR WHILE SCROLLING */
var scroll_spy = function(){
    var self = this;

    self.init = function(){

        // create listener for scrolling
        var prev_scroll = 0;
        $win = $(window);
        $win.scroll(function () {
            // track your current scroll position
            var current_scroll = $win.scrollTop();

            // check if you scrolled down or up
            if ( current_scroll < ($('.js-header').innerHeight()*0.25) ) {
                $('.js-main-menu-holder').removeClass('up');
                $('.js-main-menu-holder').removeClass('down');

                var diff_scroll = prev_scroll - current_scroll;
                prev_scroll = current_scroll;
            }else if ( prev_scroll < current_scroll ) {
                // if you scrolled down move the header up
                $('.js-main-menu-holder').addClass('up');
                $('.js-main-menu-holder').removeClass('down');
                
                var diff_scroll = prev_scroll - current_scroll; 
                prev_scroll = current_scroll;
            }else if ( prev_scroll > current_scroll ){
                // if you scrolled up move the header down
                $('.js-main-menu-holder').addClass('down');
                $('.js-main-menu-holder').removeClass('up');

                var diff_scroll = prev_scroll - current_scroll;
                prev_scroll = current_scroll;
            };
        });
    }
}


/* OPEN AND CLOSE QUESTIONS FROM FAQ */
var faq = function(){
    var self = this;

    self.init = function(){
         $('.js-faq-item').on('click', function(){
            // define new activated content
            $faq = $(this);
            $faq_a = $(this).find('.js-faq-a');

            // first check if we have an active footer-content
            var $active_content = $('.js-faq-item.active');
            if( $active_content.length > 0 ){
                if ( $faq.hasClass('active') ) {
                    $faq.removeClass('active');
                    $faq_a.slideUp(700);
                }else{
                    $active_content.find('.js-faq-a').slideUp(700);
                    $active_content.removeClass('active');
                    $faq.addClass('active');
                    $faq_a.slideDown(700);
                };
            }else{
                $(this).addClass('active');
                $faq_a.slideDown(700);
            }
        });
    }
}





/**
 * Functionality for the contact form(s)
 * Author: Mannus van der Meer
 */
var contact_form = function(){
    var self = this;
    var $form = $('.js-contact-form');

    self.init = function(){
        self.placeholder();
    }


    /**
     * Decide whether the placeholder should be shown above the input or "in" it
     * @author Mannus van der Meer
     */
    self.placeholder = function(){
        // check if one of the field already has a value, then it has been posted before, so show the captcha
        $form.find('input,textarea,select').each(function(){
            if( $(this).val() !== '' ){
                if( !$(this).hasClass('never') ){
                    $(this).addClass('valid');
                }
            }
        });

        $form.find('input,textarea,select').bind("change keyup input", function(){
            // check if form has changed and add/remove the class valid
            if( $(this).val() && !$(this).hasClass('never') ){
                if( !$(this).hasClass('valid') ){
                    $(this).addClass('valid');
                }
            }else if( !$(this).hasClass('never') ){
                if( $(this).hasClass('valid') ){
                    $(this).removeClass('valid');
                }
            }
        });
    }
}

